// app/javascript/controllers/select_all_controller.js
import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["checkbox"];

  connect(){
    console.log(this.checkboxTargets);
  }

  clearAll(event) {
    const flowId = event.currentTarget.dataset.flowId;
    const url = `/flows/${flowId}/update_review_profiles`;

    event.currentTarget.outerHTML = `
    <button id="clear_all" type="button" class="inline-flex items-center px-4 py-2 leading-6 text-sm rounded-md text-secondary transition ease-in-out duration-150 cursor-not-allowed" disabled>
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    Processing...
  </button>`

    patch(url, {
      body: JSON.stringify({
        review_profile_ids: "all",
        selected: false
      }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })
  }

  toggleAll(event) {
    const flowId = event.currentTarget.dataset.flowId;
    event.currentTarget.outerHTML = `
    <button id="select_all" type="button" class="inline-flex items-center px-4 py-2 leading-6 text-sm rounded-md text-secondary transition ease-in-out duration-150 cursor-not-allowed" disabled>
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    Processing...
  </button>`
    const url = `/flows/${flowId}/update_review_profiles`;

    patch(url, {
      body: JSON.stringify({
        review_profile_ids: "all",
        selected: true
      }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })
  }

  toggleVisible(event){
    const isChecked = event.currentTarget.checked;
    const flowId = event.currentTarget.dataset.flowId;
    const url = `/flows/${flowId}/update_review_profiles`;
    const reviewProfileIds = Array.from(this.checkboxTargets).map((checkbox) => checkbox.dataset.reviewProfileId);    

    console.log(`reviewProfileIds = ${reviewProfileIds}`)
    patch(url, {
      body: JSON.stringify({
        review_profile_ids: reviewProfileIds,
        selected: isChecked
      }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })
  }

  toggleSingle(event){
    const isChecked = event.currentTarget.checked;
    const flowId = event.currentTarget.dataset.flowId;
    const reviewProfileId = event.currentTarget.dataset.reviewProfileId;

    // Build the URL for the PATCH request
    const url = `/flows/${flowId}/update_review_profiles`;

    // Use the patch method to send the request
    patch(url, {
      body: JSON.stringify({
        review_profile_ids: [reviewProfileId],
        selected: isChecked
      }),
      contentType: "application/json",
      responseKind: "turbo-stream", // Expecting Turbo Stream response from Rails
    });
  }

}
