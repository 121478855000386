import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flow-reports"
export default class extends Controller {

  static targets = ["flowTypeSelect", "daySelect", "dayOfWeekSelect", "dayOfMonthSelect"]

  connect() {
    this.flowTypeSelectTarget.querySelector("input#selected-value").addEventListener("change", (event) => this.updateFlowType(event));
    this.updateFlowType({target: {value: this.flowTypeSelectTarget.querySelector("input#selected-value").value}});
  }

  updateFlowType(event){
    let value = event.target.value;
    console.log(this.dayOfWeekSelectTarget);
    if(value == "Daily recap"){
      this.daySelectTarget.classList.remove("hidden")
      this.dayOfWeekSelectTarget.classList.add("hidden")
      this.dayOfMonthSelectTarget.classList.add("hidden")
    }
    else if(value == "Weekly recap"){
      this.daySelectTarget.classList.add("hidden")
      this.dayOfWeekSelectTarget.classList.remove("hidden")
      this.dayOfMonthSelectTarget.classList.add("hidden")

    }
    else if (value == "Monthly recap"){
      this.daySelectTarget.classList.add("hidden")
      this.dayOfWeekSelectTarget.classList.add("hidden")
      this.dayOfMonthSelectTarget.classList.remove("hidden")
    }

  }
}
