// app/javascript/controllers/select_callback_controller.js
import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["select", "showHide"];
  static values = {
    callbackFunction: {type: String, default: "updateVisibility"}
  }

  connect() {    
    if (this.hasSelectTarget) {
      if (this.callbackFunctionValue) {
        this[this.callbackFunctionValue]();
        this.selectTarget.addEventListener("change", () => this[this.callbackFunctionValue]());
      }
    }

    // Add a listener to the form's submit event to clean up hidden fields
    const form = this.element.closest('form');
    if (form) {
      form.addEventListener('submit', this.removeHiddenFields.bind(this));
    }
  }

  updateVisibility(e) {
    const selectedValue = this.selectTarget.value;
    this.showHideTargets.forEach((target) => {
      const valuesToShowFor = target.dataset.showFor.split(",");;
      if (valuesToShowFor.includes(selectedValue)) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    });
  }

  removeHiddenFields(event) {
    // Remove all hidden elements from the form
    this.showHideTargets.forEach((target) => {
      if (target.classList.contains("hidden")) {
        target.querySelectorAll("input, select").forEach((input) => {
          input.remove();
        });
      }
    });
  }

  disconnect() {
    if (this.hasSelectTarget) {
      this.selectTarget.removeEventListener("change", this.updateVisibility);
    }
  }

  channel(){
    fetch(`/channels/${this.selectTarget.value}.json`, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if(data.provider == "slack"){
        document.querySelector("#reply_field").classList.remove("hidden")
        document.querySelector("#automation_type_all").classList.remove("hidden")
        //document.querySelector("#automation_type_reviews_only").classList.add("hidden")
      }
      else{
        document.querySelector("#reply_field").classList.add("hidden")
        document.querySelector("#automation_type_all").classList.add("hidden")
        //document.querySelector("#automation_type_reviews_only").classList.remove("hidden")
      }
    })
    .catch(error => {
      console.error('Error:', error);
      document.querySelector("#reply_field").classList.add("hidden")
      document.querySelector("#automation_type_all").classList.add("hidden")
      document.querySelector("#automation_type_reviews_only").classList.add("hidden")
    });
  } 

}
