import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() { 
    // Controller initialization logic
  }

  toggle(event) {
 
    let form = event.target.form;
    let valid = event.target.dataset.valid;
    let checkbox = event.target;
    let isChecked = checkbox.checked;
    let isNotification = checkbox.dataset.notification == "true";

    if (valid == "true"){
      if (isChecked && isNotification){
        this.openModal();
      }    
      
      form.requestSubmit();
    }

    else {
      window.location.href = `/flows/${event.target.flowId}/edit`
    }
  }

  openModal() {
    const modal = this.element.closest("[data-controller='modal']"); 
  
    if (modal) {
      const modalController = this.application.getControllerForElementAndIdentifier(modal, "modal");
      if (modalController) {
        modalController.open({ preventDefault: () => {}, target: {} });  // Use the modal controller's open method to properly handle opening
      }
    }
  }

  closeModal() {
    // Look for the closest modal/dialog element
    const modal = this.element.closest("[data-controller='modal']")
    
    if (modal) {
      const modalController = this.application.getControllerForElementAndIdentifier(modal, "modal")
      if (modalController) {
        modalController.close()
      }
    }
  }

  send_latest_review(event) {
    console.log("Sending latest review...");
    const flowId = event.target.getAttribute("data-flow-id"); // Get flow_id from the element's data attribute

    event.target.outerHTML = `<button id="${flowId}-modal-yes" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue-600 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    Processing...
    </button>`;

    // Make the POST request
    post(`/flows/${flowId}/send_latest_review`, {
      responseKind: "turbo-stream",
    })
      .then((response) => {
        if (!response.ok) {
          console.error("POST request failed");
        }
        else{
          this.closeModal();
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  }

  pull_locations(event){
    console.log("Pulling locations...");
    const googleAccountId = event.target.getAttribute("data-google-account-id"); 
    console.log(googleAccountId);

    event.target.outerHTML = `<button id="${googleAccountId}-modal-yes" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue-600 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    Processing...
    </button>`;

    // Make the GET request
    get(`/google_accounts/${googleAccountId}/locations`, {
      responseKind: "turbo-stream",
    })
      .then((response) => {
        if (!response.ok) {
          console.error("GET request failed");
        }
        else{
          this.closeModal();
        }
      })
      .catch((error) => {
        console.error("Error making GET request:", error);
      });
  }
}
